<template>
	<div>
		<div class="goods_spu">
			<header style="margin-bottom: 20px;">
				<span class="back" @click="headback">
					<i class="el-icon-arrow-left" style="font-size:14px;"></i>
					返回
				</span>
			</header>
			<div class="good_spubut">
				<div class="cation_container">
				    <div class="goods_table">
				        <el-table :data="tableData" style="width: 100%">
							<el-table-column prop="id" label="ID" width="80"></el-table-column>
				            <el-table-column prop="sku" label="SPU编号" width="280" align="center"></el-table-column>
				            <el-table-column prop="spuname"  label="商品名称" width="420">
								<template slot-scope="scope">
									<div class="goods_detail d-flex align-items-center">
										<div class="goods_detail_img">
											<img :src="scope.row.cover_pic" alt="" />
										</div>
										<div class="table_name">
											{{ scope.row.name }}
										</div>
									</div>
								</template>
				            </el-table-column>
							<el-table-column prop="price" label="售价" align="center"></el-table-column>
							<el-table-column prop="stock" label="库存" align="center"></el-table-column>
							<el-table-column prop="virtual_sales" label="销量" align="center"></el-table-column>
							<el-table-column prop="sort" label="排序" align="center"></el-table-column>
				            <el-table-column label="操作" fixed="right">
				                <template slot-scope="scope">
				                    <div class="caozuo">
				                        <!-- <button>编辑</button> -->
				                        <button @click="loopdetail(scope.row.id)">查看</button>
				                    </div>
				                </template>
				            </el-table-column>
				
				            <template slot="empty">
				                <div class="empty_data">
				                    <p>暂无数据</p>
				                </div>
				            </template>
				        </el-table>
				    </div>
				    <div class="table_foot">
						<div class="foot_left">
						</div>
				        <div class="main_pages">
				            <el-pagination @size-change="handleSizeChange" hide-on-single-page
				                @current-change="handleCurrentChange" :current-page.sync="currentPage"
				                :page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
				                layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
				            </el-pagination>
				        </div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id: this.$route.query.id,
				tableData: [], //表格数据
				currentPage: 1,
				eachPage: 10,
				total_entry: 0,
			};
		},
		components: {},
		computed: {
		},
		created() {
	
		},
		mounted() {
			this.loopspuapi();
		},
		methods: {
			loopspuapi() {
				let data = {
					order: [{
						key: "id",
						value: "desc"
					}],
					page: this.currentPage,
					limit: this.eachPage
				};
				this.$get(this.$apis.spuDetail+ this.id, data).then(res => {
					if (res.code == 200) {
						// console.log(res)
						this.tableData = res.data.list;
						this.total_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			loopdetail(id){
				this.$router.push({
					name: "goods_add",
					params: {
						id: id
					}
				});
			},
			headback() {
				window.history.go(-1)
			},
			handleSizeChange(val) {
				this.eachPage = val;
				this.loopspuapi();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.common.backTop();
				this.loopspuapi();
			},
		}
	};
</script>

<style >
	.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
		background: #535353;
	}
	
	.el-table--scrollable-x .el-table__body-wrapper {
		background: transparent;
	}
	.goods_spu {
		width: 100%;
	}
	
	.goods_detail_img {
		width: 50px;
		height: 50px;
		margin-right: 5px;
	}
	
	.goods_detail_img img {
		width: 100%;
		height: 100%;
	}
	.table_foot {
	    margin-top: 10px;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	}
	.table_name {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		flex: 1;
	}
	
	.table_name span {
		color: #2f74f5;
		background: #e9f0ff;
		padding: 1px 4px;
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		border-radius: 2px;
	}
	
	.goods_detail {
		width: 100%;
	}
	
	.goods_detail:hover .goodcompile {
		display: block;
	}

	.purchaselect {
		float: left;
		width: 270px;
	}

	.purchasebut {
		width: 100%;
	}

	.purchasebutcon button {
		float: left;
		height: 38px;
		padding: 0 16px;
		border: 1px solid #ededed;
		font-size: 14px;
		color: #666;
		background: #fff;
		outline: none;
		box-sizing: border-box;
		transition: color .2s linear, background-color .2s linear, border .2s linear, box-shadow .2s linear;
		border-radius: 4px;
		margin-right: 8px;
	}

	.purchasebutcon button:hover {
		border-color: var(--fontColor, #fb6638);
		color: var(--fontColor, #fb6638);
	}

	.purchasebutcon .purchasebutton {
		border-color: var(--fontColor, #fb6638);
		color: #fff;
		background: var(--fontColor, #fb6638);
	}

	.purchasebutcon .purchasebutton:hover {
		border-color: var(--fontColor, #fb6638);
		color: #fff;
	}
	.good_spubut{
		width: 100%;
		border-radius: 4px;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
	}
	.goods_table table th,
	table td {
	    border: none;
	}
	
	.goods_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
	    background-color: #f9f9fc;
	}
	
	.goods_table .el-table .el-table__header-wrapper {
	    background-color: #f9f9fc;
	}
	
	.goods_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
	    background-color: #f9f9fc;
	}
	
	.el-table th.el-table__cell {
	    background-color: #f9f9fc;
	    font-weight: 600;
	    color: #333;
	    padding: 8px 0;
	}
	
	.caozuo button {
	    padding: 0 10px;
		outline: none;
		border: 0;
	    border-right: 1px solid var(--fontColor, #fb6638);
	    cursor: pointer;
	    display: inline-block;
		background: transparent;
	    line-height: 1;
	    color: var(--fontColor);
	}
	
	.caozuo button:last-child {
	    border-right: none;
	}
	.main_pages {
	    display: flex;
	    align-items: center;
	    margin: 10px 0;
	}
</style>
